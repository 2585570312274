import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
  	return [ "container" ]
  }

  toggle(event) {
    event.preventDefault()
    this.containerTarget.classList.toggle('active');
  }

  toggle_on(event) {
  	event.preventDefault()
  	this.containerTarget.classList.add('active');
  }

  toggle_off(event) {
    if (this.element.contains(event.target) == false) {
      this.containerTarget.classList.remove('active');
    }
  }

}