import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "updateCardForm", "button", "newButton", "addNewCardForm", "select" ]

  toggle(event) {
  	event.preventDefault()
  	let text = this.buttonTarget.textContent;

  	this.updateCardFormTarget.classList.toggle('none');

  	if ( text == 'Update Card' ) {
  		this.buttonTarget.textContent = 'Cancel';
  	} else {
  		this.buttonTarget.textContent = 'Update Card';
  	}
  }

  new_toggle(event) {
  	event.preventDefault()
  	let text = this.newButtonTarget.textContent;

  	this.addNewCardFormTarget.classList.toggle('none');

  	if ( text == 'Add New Card' ) {
  		this.newButtonTarget.textContent = 'Cancel';
  	} else {
  		this.newButtonTarget.textContent = 'Add New Card';
  	}
  }

  send_to_billing_cycle() {
    let select = this.selectTarget;
    let url = select.options[select.selectedIndex].value;

    window.location.replace(url);
  }

  connect() {
    // Load Stripe
    const apiKey = document.querySelector('meta[name=stripe-key]');
    const stripe = Stripe(apiKey.content);

    // Create elements
    const elements = stripe.elements();
    const cardElement = elements.create('card');
    cardElement.mount('#card-element');

    // Process card details
    const cardholderName = document.getElementById('cardholder-name');
    const setupForm = document.getElementById('setup-form');
    const clientSecret = setupForm.dataset.secret;

    setupForm.addEventListener('submit', function(ev) {
      ev.preventDefault();
      stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardholderName.value,
            },
          },
        }
      ).then(function(result) {
        if (result.error) {
          // Display error.message in your UI.
          alert(result.error.message);
        } else {
          // The setup has succeeded.
          //console.log(result.setupIntent.payment_method);

          // Handle success message

          // Create hidden fields to store card data
          setPaymentMethodField(result.setupIntent.payment_method);

          // Submit the form
          setupForm.submit();
        }
      });
    });

    function setPaymentMethodField(payment_method) {
      const hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'user[stripe_payment_method]');
      hiddenInput.setAttribute('value', payment_method);
      setupForm.appendChild(hiddenInput);
    }
  }

}