import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [ "countryDropdown", "stateDropdown", "container" ]
  }

  connect() {
    const country = this.countryDropdownTarget.options[this.countryDropdownTarget.selectedIndex].value;
    const field = '<input type="text" name="job[state]" id="job_state">';
    const container = this.containerTarget;

    if ( country != 'USA' ) {
      this.stateDropdownTarget.remove();
      container.insertAdjacentHTML('beforeend', field);
    } else {

    }
  }

  setStateField(event) {
  	const country = this.countryDropdownTarget.options[this.countryDropdownTarget.selectedIndex].value;
  	const field = '<input type="text" name="job[state]" id="job_state">';
  	const container = this.containerTarget;

  	if ( country != 'USA' ) {
  		this.stateDropdownTarget.remove();
  		container.insertAdjacentHTML('beforeend', field);
  	} else {

  	}

  }

}