import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countryDropdown", "stateDropdown", "container", "companyName", "roleDropdown", "subscriptionContainer", "subscriptionButton", "subscriptionField", "bioPoints", "companySize", "software" ]

  connect() {
    const role = this.roleDropdownTarget.options[this.roleDropdownTarget.selectedIndex].value;
    const subscriptionId = this.subscriptionButtonTargets[0].dataset.id;

    if ( role == 'hiringcompany' || role == 'designcompany' ) {
      this.companyNameTarget.classList.remove('none');
      this.bioPointsTarget.classList.remove('none');
    } else {
      this.companyNameTarget.classList.add('none');
    }

    if ( role == 'designer' ) {
      this.bioPointsTarget.classList.remove('none');
    }

    if (role == 'partner') {
      this.companyNameTarget.classList.remove('none');
    }

    if ( role == 'designcompany' ) {
      this.subscriptionContainerTarget.classList.remove('none');
      this.subscriptionFieldTarget.value = subscriptionId;
      this.companySizeTarget.classList.remove('none');
      this.softwareTarget.classList.remove('none');
    } else {
      this.subscriptionContainerTarget.classList.add('none');
      this.subscriptionFieldTarget.value = null;
      this.softwareTarget.classList.add('none');
    }
  }

  setStateField(event) {
  	const country = this.countryDropdownTarget.options[this.countryDropdownTarget.selectedIndex].value;
  	const field = '<input type="text" name="user[state]" id="user_state">';
  	const container = this.containerTarget;

  	if ( country != 'USA' ) {
  		this.stateDropdownTarget.remove();
  		container.insertAdjacentHTML('beforeend', field);
  	} else {

  	}

  }

  toggleCompanyName() {
    const role = this.roleDropdownTarget.options[this.roleDropdownTarget.selectedIndex].value;
    const subscriptionId = this.subscriptionButtonTargets[0].dataset.id;

    if ( role == 'hiringcompany' || role == 'designcompany' || role == 'partner' ) {
      this.companyNameTarget.classList.remove('none');
    } else {
      this.companyNameTarget.classList.add('none');
    }

    if ( role == 'designcompany' ) {
      this.subscriptionContainerTarget.classList.remove('none');
      this.subscriptionFieldTarget.value = subscriptionId;
    } else {
      this.subscriptionContainerTarget.classList.add('none');
      this.subscriptionFieldTarget.value = null;
    }
  }

  setSubscription(event) {
    event.preventDefault();

    const planButton = event.target;

    // Process all buttons
    this.subscriptionButtonTargets.forEach(function(button) {
      if ( button == planButton ) {
        button.classList.remove('green');
        button.classList.add('disabled');
        button.text = 'Selected';
      } else {
        button.classList.remove('disabled');
        button.classList.add('green');
        button.text = 'Select';
      }
    });

    // Set subscriptionField value
    this.subscriptionFieldTarget.value = planButton.dataset.id;

  }

  roleFields(event) {
    const role = this.roleDropdownTarget.options[this.roleDropdownTarget.selectedIndex].value;
    const bioPoints = this.bioPointsTarget
    const companySize = this.companySizeTarget
    const software = this.softwareTarget

    if ( role == 'designcompany' ) {

      bioPoints.classList.remove('none');
      companySize.classList.remove('none');
      software.classList.remove('none');

    } else if ( role == 'hiringcompany' ) {

      bioPoints.classList.remove('none');
      companySize.classList.add('none');
      software.classList.add('none');

    } else if ( role == 'designer' ) {

      bioPoints.classList.remove('none');
      companySize.classList.add('none');
      software.classList.add('none');

    } else if ( role == 'partner' ) {

      bioPoints.classList.add('none');
      companySize.classList.add('none');
      software.classList.add('none');

    }
  }

}