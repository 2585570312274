import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [ "industrySelect", "filters", "industryField" ]
  }

  connect() {
    const industry = this.industryFieldTarget.value;
    const filters = this.filtersTarget;

    if ( industry > 0 ) {
      filters.classList.remove('none');
      this.industrySelectTarget.value = industry;
    }
  }

  toggleFilters() {
  	const industry = this.industrySelectTarget.options[this.industrySelectTarget.selectedIndex].value;
  	const filters = this.filtersTarget;

  	if ( industry == 'none' ) {

  		// If the select has no industry selected
  		filters.classList.add('none');

  	} else {

  		// Show the filters
  		filters.classList.remove('none');

  		// Populate the hidden industry field with the proper value
  		this.industryFieldTarget.value = industry;

  	}
  }

}