import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [  ]
  }
  static values = { user: String }

  connect() {
    getUserJobs(this.userValue)
  }


}

function getUserJobs(user_id) {

  // Query User Jobs
  fetch('/users/' + user_id + '/user_jobs.json')
    .then(function (response) {
      response.json().then(function (data) {
        buildJobOptions(data)
      })
    })

}

function buildJobOptions(data) {
  const jobSelect = document.getElementById('job_email_job_id')
  let options = ''

  // If user has jobs build out options
  if ( data.length != 0 ) {
    data.forEach(option => {
      options += '<option value="' + option.id + '">' + option.name + '</option>'
    })
  } else {
    options += '<option>Create a Job before sending an email</option>'
  }

  jobSelect.innerHTML = options
}